@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}

body {
  background-color: #eaf1fd;
  margin: 0;

  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
©
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiBackdrop-root {
  background-color: transparent !important;
}

.MuiList-padding {
  padding: 20px !important;
}

.ListPrivacyPolicy {
  margin-left: -25px !important;
}

.ListPrivacyPolicy li {
  margin-bottom: 5px;
}
.captcha-padding{
  padding-top: 20px;
  padding-bottom: 20px;
}
.scroll-container {
  overflow: hidden;
}

.scroll-text {
  /* animation properties */
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-animation: my-animation 4s linear infinite;
  animation: my-animation 4s linear infinite;
}

/* for Firefox */

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
*{
  box-sizing: border-box;
}
/********** chakra page **************/
 ul.auraTypeName {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  padding: 10px 5px;
  list-style-type: none;
  height: 100%;
  background: #0006;
  margin: 0;
  font-size: 12px;
  width: 170px;
  color: #ffffff
 }

ul.auraTypeName li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1px;
  padding: 5px;
}
ul.auraTypeName li.acitveChakra{
  background: linear-gradient(90deg, #f10f10, #1b6cba);
}
.bg_screen.bg_chakra{
  background-image: url(/static/media/chakra-bg2.c66908dd.png);
}
/********** chakra page ends**************/
.videoContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
label.MuiInputLabel-shrink {
  background: #ffffff !important;
  padding: 5px !important;
  top: -5px !important;
  border-radius: 5px !important;
  color: #000 !important;
}
.MuiFormControl-root label:not(.MuiInputLabel-shrink){
  color: #ffffff !important;
}
/******** user records *****/
.userRecords-wrapper{
  width: 400px;
  max-width: 100%;
  margin: 30px auto;
  padding: 0 15px;
  background:#00000074;
  background-repeat: no-repeat;
  background-size: cover;
  min-height:75vh ;
}
.userRecords-header{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px 0;
}
.userRecords-header .MuiButton-text{
    border-radius: 8px;
    border: 4px solid #07263e;
    position: relative;
    background: #133e5f !important;
    color: #fff;
    padding: 5px 15px;
    box-sizing: border-box;
}
.userRecords-header .MuiButton-text::before{
    content: "";
      position: absolute;
      left: 0;
      top: -4px;
      right: 0;
      margin: auto;
      height: 4px;
      width: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
}
.userRecords-header .MuiButton-text::after{
  content: "";
  position: absolute;
  top: auto;
  bottom: -4px;
  right: 0;
  width: 100%;
  margin: auto;
  height: 4px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
}
.userRecords-bodyList{
  padding: 0 15px 15px;
  margin-bottom: 15px;
  border-bottom:1px solid #ffffff ;
  display: -webkit-flex;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  -webkit-align-items: center;
          align-items: center;
}
.userRecords-bodyList img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.userRecords-bodyList p {
  width: calc(100% - 40px);
  margin: 0;
}
.userRecords-bodyList:last-child{
  border-bottom: 0;
}
.userRecords-body p{
  color: #ffffff;
}
.analyzeButton button{
  position: relative;
}
.analyzeButton button .MuiCircularProgress-root{
  position: absolute;
  background: #fff;
  border-radius: 50%;
}
.analyzeButton button .MuiCircularProgress-root svg{
  position: absolute;
  left: 50%;
  top:50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.analyzeButton button .MuiButton-label{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.fadeinout {
    -webkit-animation: fadeinout 3s infinite;
            animation: fadeinout 3s infinite;
}

.animate_wrap {
    position: absolute;
    z-index: 50;
}

.bg_screen {
    background: url(/static/media/scanRound.5fd71450.png);
    background-size: cover;
    min-height: calc(100vh - 200px);
    height: calc(100vh - 500px);
    width: 100vw;
    margin-right: -20px !important;
    margin-left: -20px !important;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
}

.success_bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg_screen::before {
    position: absolute;
    height: 324px;
    width: 318px;
    top: 52%;
    left: 50%;
    content: "";
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    background-image: url(/static/media/auraScan1.df0e3345.png);
    /* animation: image 2s infinite alternate; */
}

.bg_chakra::before {
    background-image: url(/static/media/auraScan.6cca6975.png) !important;
    width: 332px;
}

.scan-img-wrap img {
    width: 110px;
    height: 110px;
    border-radius: 25px;
}

.scan-img-wrap {
    position: absolute;
    left: 50%;
    top: 52.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.scan-btn-wrap {
    position: absolute;
    bottom: -3%;
    width: 100%;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 20;
}

.scan-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.loader-wrap {
    text-align: center;
}

.animate_wrap img {
    width: 100%;
}

.modal_dialog {
    height: 50vh;
    background-color: #0d3c61;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

input[type="text"] {
    color: #fff;
}

.pdf-btn-wrap {
    position: absolute;
    bottom: 10%;
    right: 0px;
}

.pdf-btn-wrap button {
    color: #fff;
    font-size: 30px;
}

@media screen and (max-width:575px) {
    .modal_dialog {
        height: 35vh;
    }

    .scan-btn-wrap {
        width: 100%;
    }
}

@-webkit-keyframes fadeinout {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/************ user recorder css *************/
.userRecoderORCon{
    padding: 10px 20px;
}
 
.userRecoderORCon .MuiFormControl-root.MuiTextField-root,
.userRecoderORCon textarea,
.userRecoderORCon button {
    border-radius: 8px;
    border: 4px solid #07263e;
    position: relative;
    background: #133e5f !important;
    color: #fff;
    max-width: 100%;
    min-width: 0;
    box-sizing: border-box;
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root::after,
.userRecoderORCon .MuiFormControl-root.MuiTextField-root::before,
.userRecoderORCon textarea::after,
.userRecoderORCon textarea::before,
.userRecoderORCon button:not([aria-label="Choose date"])::after,
.userRecoderORCon button:not([aria-label="Choose date"])::before {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    right: 0;
    margin: auto;
    height: 4px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&0+0,1+50,0+100 */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root::after,
.userRecoderORCon textarea::after,
.userRecoderORCon button:not([aria-label="Choose date"])::after {
    top: auto;
    bottom: -4px;
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root input::-webkit-input-placeholder, .userRecoderORCon textarea::-webkit-input-placeholder {
    color: #fff;
    text-align: center;
    opacity: 1;
    text-transform: capitalize;
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root input:-ms-input-placeholder, .userRecoderORCon textarea:-ms-input-placeholder {
    color: #fff;
    text-align: center;
    opacity: 1;
    text-transform: capitalize;
}

.userRecoderORCon .MuiFormControl-root.MuiTextField-root input::placeholder,
.userRecoderORCon textarea::placeholder {
    color: #fff;
    text-align: center;
    opacity: 1;
    text-transform: capitalize;
}

.userRecoderORCon textarea::-webkit-input-placeholder {
    text-align: left;
    padding: 10px;
    background: #17659c !important;
}

.userRecoderORCon textarea:-ms-input-placeholder {
    text-align: left;
    padding: 10px;
    background: #17659c !important;
}

.userRecoderORCon textarea,
.userRecoderORCon textarea::placeholder {
    text-align: left;
    padding: 10px;
    background: #17659c !important;
}

.userRecoderORCon button.MuiButtonBase-root.customBtn.customStartBtn {
    color: red;
}

.userRecoderORCon button.MuiButtonBase-root.MuiButton-containedPrimary {
    width: 100%;
    font-size: 20px;
    padding: 10px 20px 10px 20px !important;
}

.userRecoderORCon button svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    fill: #fff;
}

.ks .MuiDialog-paperWidthSm {
    max-width: 710px !important;
    height: 610px;
}

.ks .MuiDialogContent-root:first-child {
    padding-top: 20px;
    text-align: center;
    width: 688px;
    max-width: 100%!important;
}
.ks .MuiDialogContent-root:first-child img{
    max-width: 100% !important;
}
.ks .MuiDialogContent-root:first-child video{
    max-height: 50vh;
    max-width: 100%;
}

.custom-pop-up .MuiDialog-paperWidthSm {
    max-width: 75%;
    width: 100%;
    min-height: 650px;
    height: auto;
}
#custom-date-area label {
    color: #fff !important;
}

.MuiDialogContent-root img.false {
    max-width: 100% ;
    height: auto;
}
div#birth-date-area div:first-child {
    padding-bottom: 6px;
}
